<template>
  <v-row dense>
    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.name" :label="$t('shared.name')" :rules="'required|max:100'" dir="auto" />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <c-input v-model="value.uniqueId" :label="$t('shared.uniqueId')" rules="required|alpha_dash" dir="ltr"  />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <auto-complete
        v-model="value.organizationId"
        :search="organizationSearch"
        :select="organizationSelect"
        @input="resetUser"
        :label="$t('organizations.organization')"
        :rules="'required'"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <auto-complete
        v-model="value.userId"
        item-text="fullName"
        :disabled="!value.organizationId"
        item-value="id"
        :search="userSearch"
        :select="userSelect"
        :label="$t('users.user')"
      />
    </v-col>

    <v-col cols="12" sm="6" md="4" class="d-flex align-center">
      <v-switch v-model="value.active" :label="$t('shared.active')" inset />
    </v-col>

    <v-col cols="12">
      <object-editor v-model="value.configs" :label="$t('devices.deviceConfigs')" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BaseForm',
  props: {
    value: {type: Object, default: () => ({})}
  },
  data() {
    return {
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: this.$t('shared.fullName'), value: 'fullName', sortable: false},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ]
    }
  },
  methods: {
    resetUser() {
      this.value.userId = null
    },
    userSearch(filter) {
      filter.organizationId = this.value.organizationId
      return this.$api.users.getAllInfo(filter)
    },
    userSelect() {
      return Promise.resolve(this.value.userInfo)
    },
    organizationSearch(filter) {
      filter.parent = false
      return this.$api.organizations.getAllInfo(filter)
    },
    organizationSelect() {
      return Promise.resolve(this.value.organizationInfo)
    }
  }
}
</script>
